var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"program-setup-wizard__dialog program-setup-wizard__program",attrs:{"rounded":"true"}},[_c('v-card-title',[_vm._v("Automate Setup")]),_c('v-card-subtitle',[_vm._v(_vm._s(("your AI generate program for " + _vm.website)))]),_c('v-card-text',[_c('v-container',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',_vm._l((_vm.finalProgramData),function(field,key){return _c('v-row',{key:key},[(key === 'program_schema_version')?_c('div'):_vm._e(),(key === 'adks')?_c('v-col',{attrs:{"cols":"12"}},_vm._l((_vm.finalProgramData[key]),function(adk,index){return _c('v-row',{key:((adk.name) + "." + index)},[_c('h3',[_vm._v(_vm._s(adk.name))]),_vm._l((adk),function(adkField,adkKey){return _c('v-col',{key:((adk.name) + "." + adkKey),attrs:{"cols":"12"}},[(adkField.isArray)?_c('v-chip-group',_vm._l((adkField),function(el){return _c('v-chip',{key:el,model:{value:(
                        _vm.finalProgramData[key].find(function (programADK) { return programADK.name === adk.name; })[
                          adkKey
                        ]
                      ),callback:function ($$v) {_vm.$set(_vm.finalProgramData[key].find(function (programADK) { return programADK.name === adk.name; }), 
                          adkKey
                        , $$v)},expression:"\n                        finalProgramData[key].find(programADK => programADK.name === adk.name)[\n                          adkKey\n                        ]\n                      "}},[_vm._v(" "+_vm._s(el))])}),1):_c('v-text-field',{attrs:{"label":adkKey},model:{value:(
                      _vm.finalProgramData[key].find(function (programADK) { return programADK.name === adk.name; })[
                        adkKey
                      ]
                    ),callback:function ($$v) {_vm.$set(_vm.finalProgramData[key].find(function (programADK) { return programADK.name === adk.name; }), 
                        adkKey
                      , $$v)},expression:"\n                      finalProgramData[key].find(programADK => programADK.name === adk.name)[\n                        adkKey\n                      ]\n                    "}},[_vm._v(" "+_vm._s(_vm.adkFields)+" ")])],1)})],2)}),1):_c('v-text-field',{attrs:{"label":key},model:{value:(_vm.finalProgramData[key]),callback:function ($$v) {_vm.$set(_vm.finalProgramData, key, $$v)},expression:"finalProgramData[key]"}},[_vm._v(" "+_vm._s(field)+" ")]),(field.isArray)?_c('v-chip-group',_vm._l((field),function(element){return _c('v-chip',{key:element,model:{value:(_vm.finalProgramData[key]),callback:function ($$v) {_vm.$set(_vm.finalProgramData, key, $$v)},expression:"finalProgramData[key]"}},[_vm._v(" "+_vm._s(element))])}),1):_vm._e()],1)}),1)],1)],1)],1),_c('v-card-actions',[_c('v-row',{staticClass:"pa-0 pb-4 pr-4",attrs:{"justify":"end","no-gutters":""}},[_c('v-col',{staticClass:"shrink"},[_c('v-btn',{staticClass:"white--text",attrs:{"color":"orange","rounded":""},on:{"click":_vm.saveProgram}},[_vm._v("Create Program")])],1),_c('v-col',{staticClass:"ml-2 shrink"},[_c('v-btn',{staticClass:"white--text",attrs:{"color":"red","rounded":""},on:{"click":_vm.saveProgram}},[_vm._v("Cancel")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }