





































































import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import axios from 'axios';
import { object } from 'prop-types';

export default defineComponent({
  props: {
    website: {
      type: String,
      required: true
    },
    value: {
      type: Object,
      required: true
    }
  },
  setup(props, context) {
    const { emit } = context;
    function deepCopy(obj) {
      if (typeof obj !== 'object' || obj === null) {
        return obj;
      }
      const isArray = Array.isArray(obj);
      const newObj = isArray ? [] : {};
      Object.keys(obj).forEach(key => {
        newObj[key] = deepCopy(obj[key]);
      });
      return newObj;
    }
    const finalProgramData = computed(() => ({ ...props.value }));
    // watch(finalProgramData, () => {
    //   console.log(finalProgramData, finalProgramData.value);
    //   emit('input', finalProgramData.value);
    // });
    function saveProgram() {
      emit('saveProgram', this.finalProgramData);
    }
    return {
      finalProgramData,
      saveProgram
    };
  }
});
